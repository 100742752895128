<template lang="pug">
button#fb.btn-social.mb-3.fb_login_button_container.form-control(
  dir="auto"
  :aria-label="label"
  :disabled="loading"
  @click="facebookLogin"
)
  .btn-social-icon.mr-2.d-inline-block
    svg.fb_button_svg_logo(width="18px",height="18px",viewBox="0 0 213 213",preserveAspectRatio="xMinYMin")
      path.f_logo_circle(d="M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,0,1,0,-32 0" fill="#1A77F2")
      path.f_logo_f(d="M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,1,0,1,-32 0" fill="#ffffff")
  .btn-social-label.d-inline-block(data-i18n="memberContinueWith") {{ label }}
</template>

<script>
import { mapState, mapActions } from 'pinia'
import cognitoMixin from '@layers/web/mixins/cognito.mixin'

export default defineNuxtComponent({
  setup () {
    const { public: { FACEBOOK_APP_ID } } = useRuntimeConfig()

    return {
      FACEBOOK_APP_ID,
    }
  },

  mixins: [cognitoMixin],

  emits: ['click'],

  data () {
    return {
      loading: true,
      version: 'v16.0',
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),

    label () {
      return `${this.$t('memberContinueWith')} Facebook`
    },
  },

  created () {
    if (process.browser) {
      this.loadFbSdk()
        .catch(() => {})
    }
  },

  methods: {
    ...mapActions(useUserStore, {
      clearUserForLogin: 'clearUserForLogout',
    }),

    loadFbSdk () {
      const appId = this.FACEBOOK_APP_ID
      const version = this.version
      return new Promise((resolve, reject) => {
        if (!process.browser) {
          reject(new Error('client side only'))
        }
        window.fbAsyncInit = function () { // eslint-disable-line func-names
          window.FB.init({
            appId,
            xfbml: false,
            version,
            cookie: true,
          })
          window.FB.AppEvents.logPageView()
          resolve()
        };
        (function (d, s, id) { // eslint-disable-line func-names
          const fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) { return }
          const js = d.createElement(s); js.id = id
          js.src = '//connect.facebook.net/en_US/sdk.js'
          fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))

        this.loading = false
      })
    },

    facebookLogin () {
      this.initiatingSocialLogin()

      // Skip the redirect window?..
      // https://www.facebook.com/dialog/oauth/?client_id=1470413516498692&redirect_uri=http://localhost:3000/medlem/callback?login&scope=email,public_profile
      this.$emit('click')
      if (process.browser) {
        window.FB.login((response) => {
          if (response.status === 'connected') {
            try {
              this.loginWithFacebookParseResponse(response.authResponse)
            } catch (error) {
              this.$sentrySetContext('exception', JSON.stringify(error))
              this.$sentryCaptureException('facebook > sdk > login error', {
                level: 'info',
                tags: {
                  type: 'auth',
                },
              })
            }
          } else {
            this.$sentrySetContext('response', JSON.stringify(response))
            this.$sentryCaptureException('facebook > cognito > uncaught response', {
              level: 'info',
              tags: {
                type: 'auth',
              },
            })
          }
        }, { scope: 'public_profile,email,openid' })
      }
    },

    async loginWithFacebookParseResponse (response) {
      if (!response.accessToken) {
        return
      }

      const { data: internalVerification } = await apiFetch(`/${this.locale}/customer/facebookJwt`, {
        method: 'POST',
        throwOnFail: false,
        body: response.accessToken,
      })

      if (internalVerification) {
        const { jwt, ...info } = internalVerification

        const user = {
          name: info.name,
          email: info.email,
          picture: info?.picture?.data?.url ?? info?.picture,
        }
        this.cognitoFederatedSignInWithOptions('graph.facebook.com', jwt, user)
          .then(cred => this.cognitoGetUser(cred, response.accessToken, user))
          .then(authUser => this._handleNewToken(jwt, authUser))
          .catch((x) => {
            this.$sentrySetContext('exception', JSON.stringify(x))
            this.$sentryCaptureException('facebook > cognito > error', {
              level: 'info',
              tags: {
                type: 'auth',
              },
            })
          })
      } else {
        this.$sentrySetContext('response', JSON.stringify(response))
        this.$sentryCaptureException('facebook > internal verification > error', {
          level: 'info',
          tags: {
            type: 'auth',
          },
        })
      }
    },
  },
})
</script>
